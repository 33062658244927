<template>
  <transition name="fade">
    <div :key="parts[0]?.data.id" class="">
      <component
        :is="component.component"
        v-for="(component, index) of parts"
        :id="previousIsMap(index) ? undefined : component.data?.slug"
        :key="component.data?.id"
        :payload="component.data"
        class="fade-item"
        :scroll-y="scrollPosition"
        :no-anchors="noAnchors"
        :previous-is-map="previousIsMap(index)"
        :map-payload="mapPayload(index)"
        :index="index"
        :is-last-part="index === parts.length - 1"
      >
        <h2>Component «{{ component.component }}» not registered.</h2>
      </component>
    </div>
  </transition>
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { useWithinBreakpoint } from '@/hooks/breakpoints';

export default {
  components: {

    NotFound: defineAsyncComponent(() => import('@/components/structure/NotFound.vue')),
    Footer: defineAsyncComponent(() => import('@/components/structure/Footer.vue')),

    Row: defineAsyncComponent(() => import('@/components/structure/Row.vue')),
    MapRow: defineAsyncComponent(() => import('@/components/parts/MapRow.vue')),
    Contact: defineAsyncComponent(() => import('@/components/structure/Contact.vue')),
    Kontakt: defineAsyncComponent(() => import('@/components/structure/Contact.vue')),

    LegalInformation: defineAsyncComponent(() => import('@/components/structure/OtherPage.vue')),
    PrivacyPolicy: defineAsyncComponent(() => import('@/components/structure/OtherPage.vue')),
    Datenschutz: defineAsyncComponent(() => import('@/components/structure/OtherPage.vue')),
    Imprint: defineAsyncComponent(() => import('@/components/structure/OtherPage.vue')),
    Impressum: defineAsyncComponent(() => import('@/components/structure/OtherPage.vue')),

  },
  props: { inject: { type: Object, default: Object } },
  setup() {
    return {
      isXsScreen: useWithinBreakpoint('xs'),
      isSmScreen: useWithinBreakpoint('sm'),
      isMdScreen: useWithinBreakpoint('md'),
      isLgScreen: useWithinBreakpoint('lg'),
    };
  },
  data() {
    return {
      scrollPosition: 0,
    };
  },
  computed: {
    initializeTeleport() {
      return !!document.querySelector('#mobileAnchorLinks');
    },
    parts() {
      return this.inject.filter((part) => !part.data?.hidden);
    },
    noAnchors() {
      return this.parts.every((part) => part.data.ishidden?.length);
    },
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
    previousIsMap(index) {
      return this.parts[index - 1]?.component === 'MapRow';
    },
    mapPayload(index) {
      return this.parts[index - 1]?.data;
    },
    currentIsMap(index) {
      return this.parts[index]?.component === 'MapRow';
    },
  },
};
</script>
