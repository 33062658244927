<template>
  <div class="PageTitle">

    <div v-if="page.meta?.motto" class="motto-wrapper">
      <Go
        :to="page.meta.aboutlink"
        @mouseover="toggleHover"
        @focusin="toggleHover"
        @mouseleave="toggleHover"
        @focusout="toggleHover"
      >
        <h1 class="motto-text" :class="{hovered: isHovered}">
          {{ page.meta?.motto }}
        </h1>
      </Go>
      <ArrowLink :to="page.meta.aboutlink" :class="['text-base', 'pt-2', 'text-center', isHovered && 'hovered']">
        {{ page.meta.mottoReadMore }}
      </ArrowLink>
    </div>

    <h1 v-else-if="!isSmScreen" class="regular-title">
      {{ page.meta?.title }}
    </h1>

    <div v-else class="mobile-anchors">
      <h1 v-if="isSmScreen && !page.meta?.motto" class="regular-title">
        {{ page.meta?.title }}
      </h1>
      <div id="mobileAnchorLinks" />
    </div>

  </div>
</template>

<script>
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import Go from '../utils/Go.vue';
import ArrowLink from './ArrowLink.vue';

export default {
  components: {
    Go,
    ArrowLink,
  },
  props: { motto: String, aboutlink: String },
  setup() {
    return {
      isXsScreen: useWithinBreakpoint('xs'),
      isSmScreen: useWithinBreakpoint('sm'),
      isMdScreen: useWithinBreakpoint('md'),
      isLgScreen: useWithinBreakpoint('lg'),
    };
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    page() {
      return this.$store.state.router.page;
    },
  },
  methods: {
    toggleHover() {
      this.isHovered = !this.isHovered;
    },
  },
};
</script>

<style scoped lang="scss">
.PageTitle {
  @apply grid place-items-center w-full py-1 px-4 pb-11;
  --half-minus-nav: calc(50vh - var(--main-nav-height));
  min-height: calc(var(--half-minus-nav) - 1.25rem);

  @include mediasm {
    @apply my-6 py-12;
    height: unset;
  }

  h1 {
    @apply text-center text-lgxl leading-tight;
    @include mediasm {
      @apply text-lg;
    }
    @include mediaxs {
      @apply text-md;
    }

    &.regular-title {
      @apply text-red;
    }
  }

  .motto-wrapper {
    @apply w-full flex flex-col items-center justify-center text-center;
    @include smoothHover;

    .motto-text {
      @apply max-w-3xl;
    }
  }

  .mobile-anchors {
    #mobileAnchorLinks {
      @apply flex flex-col items-center gap-2 mt-6 text-base font-bold text-red;
    }
  }

}

</style>
