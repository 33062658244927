<template>
  <footer class="Footer constrain text-sm">
    <!--    <button class="backToTop" @click="scrollToTop">-->
    <!--      Back to top-->
    <!--    </button>-->

    <div class="branding">
      <Go :to="'/' + $store.state.router.locale" aria-label="Home">
        <LogoDarkWhite />
        <span>Swiss 5 Group</span>
      </Go>
    </div>

    <div class="addresses-wrapper">

      <div class="aw--names">
        <div v-if="meta?.customFooterCompany" v-html="meta?.customFooterCompany" />
        <div v-else v-html="footer?.companyname" />
      </div>

      <div class="aw--contact">
        <div v-if="meta?.customFooterContact" v-html="meta?.customFooterContact" />
        <div v-else v-html="footer?.companycontact" />
      </div>

    </div>

    <div class="contact">
      <FooterLink :payload="{uri: footer?.contactlink, text: footer?.contacttext}" />
      <!--      <FooterLink :payload="{uri: footer?.newsletterlink, text: 'Newsletter'}" />-->
      <FooterLink
        :payload="{
          links: [
            { icon: 'linkedin', uri: footer?.linkedinlink },
            { icon: 'instagram', uri:footer?.instagramlink }
          ],
          text: footer?.followustext}"
      />
    </div>

    <div class="legal">
      <FooterLink :payload="{uri: footer?.privacylink, text: footer?.privacytext}" />
      <FooterLink :payload="{uri: footer?.imprintlink, text: footer?.imprinttext}" />
      <FooterLink :payload="{iscopyright: true, text: footer?.copyright}" />
    </div>

  </footer>
</template>

<script>
import LogoDarkWhite from '../../assets/images/logo-dark-white.svg';
import Go from '../utils/Go.vue';
import FooterLink from '../parts/FooterLink.vue';

export default {
  components: {
    Go,
    LogoDarkWhite,
    FooterLink,
  },
  props: {
    payload: Object,
  },
  computed: {
    footer() {
      return this.$store.state.router.layout.footer;
    },
    meta() {
      return this.$store.state.router.page?.meta;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped lang="scss">
.Footer {
  @apply relative grid bg-gray-900 text-white pb-0 pt-6 px-6 gap-y-6 h-fit; // mt-16;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 3rem;
  grid-template-areas:
  "branding addresses"
  "contact legal";

  @include mediamd {
    @apply gap-y-0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
    "branding addresses"
    "contact contact"
    "legal legal";
  }

  @include mediasm {
    @apply gap-y-0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
    "branding"
    "addresses"
    "contact"
    "legal";
  }
  @include mediaxs {
    @apply px-4 pt-5;
  }

  .backToTop {
    all: unset;
    @apply absolute w-fit flex items-center text-black;
    right: 1.5rem;
    top: -2.5rem;
    gap: 0.75ch;

    &::after {
      content: "\2191";
      font-size: larger;
      line-height: 1;
      transition: 0.25s ease-in-out;
      transition-property: transform, color;
    }

    &:is(:hover, :focus) {
      cursor: pointer;
      color: var(--color-red);

      &::after {
        transform: scale(1.5) translateY(-2px);
      }
    }
  }

  .branding {
    @apply -mt-4 text-sm h-fit;
    grid-area: branding;

    @include mediasm {
      @apply pb-4;
    }

    a {
      @apply flex items-center h-16 w-fit whitespace-nowrap;

      @include mediaxs {
        @apply h-14;
      }

      &:is(:hover, :focus) {
        @apply cursor-pointer;
      }
    }

    svg {
      @apply h-full w-auto;
    }

    span {
      @apply block text-white ml-1 w-max;
    }

  }

  .addresses-wrapper {
    grid-area: addresses;
    @apply flex gap-9 min-h-10 pl-7 h-fit;
    @include mediamd {
      @apply gap-4;
    }
    @include mediaxs {
      @apply flex-col gap-0 pl-14 -ml-1.5;
    }

    .aw--names, .aw--contact {
      @include mediamd {
        @apply basis-1/2 px-1.5;
      }
      @include mediaxs {
        @apply pt-2 pb-4;
        flex-basis: unset;
      }

      >div {
        :deep(p) {
          @apply mb-4;

          &:last-child {
            @apply mb-0;
          }
        }
      }
    }

    @include mediasm {
      .aw--names >div :deep(p):first-child { display: none; }
    }
  }

  .contact, .legal {
    @apply -mx-6 pl-7 pr-2 pt-1 flex gap-6 border-t h-fit;
    width: calc(100% + 3rem);

    @include mediamd {
      height: unset;
      @apply items-center h-12 pl-8;
    }
    @include mediaxs {
      @apply pl-20;
      height: unset;
      width: calc(100% + 2.5rem);
    }

  }

  .contact {
    grid-area: contact;
  }
  .legal {
    grid-area: legal;
    @apply gap-y-0 pl-14 -translate-x-1;
    @include mediamd {
      @apply pl-8 -translate-x-0;
    }

    @include mediaxs {
      @apply flex-wrap pl-20 -translate-x-0;
    }
  }
}
</style>
