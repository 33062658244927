<template>
  <div class="Language">
    <a
      @click.prevent="swap(locales[locale].target.key)"
    >{{ locales[locale].target.name }}</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      locales: {
        en: {
          key: 'en',
          target: {
            key: 'de',
            name: 'Deutsch',
          },
        },
        de: {
          key: 'de',
          target: {
            key: 'en',
            name: 'English',
          },
        },
      },
    };
  },
  computed: {
    locale() {
      return this.$store.state.router.locale || this.locales.de.key;
    },
  },
  methods: {
    swap(targetKey) {
      this.$store.dispatch('router/push', { uri: this.$store.state.router.page.meta?.translatedPaths[targetKey] });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
