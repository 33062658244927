<template>
  <div id="overlay" />

  <Navigation v-if="navigation" />

  <AnchorsMenu />

  <PageTitle
    v-if="meta"
    :key="content[0]?.data.id"
    :motto="meta.motto"
    :aboutlink="meta.aboutlink"
  />

  <main class="bg-white w-full grid relative text-base">
    <Dynamic v-if="content" :inject="content" />
  </main>

  <Cookie />

  <Footer />
</template>

<script>
import Navigation from '@/components/structure/Navigation.vue';
import AnchorsMenu from '@/components/structure/AnchorsMenu.vue';
import Dynamic from '@/components/structure/Dynamic.vue';
import dynamicPage from '@/hooks/dynamicPage';
import PageTitle from '@/components/parts/PageTitle.vue';
import Footer from '@/components/structure/Footer.vue';
import Cookie from '@/components/utils/Cookie.vue';
import { useWithinBreakpoint } from './hooks/breakpoints';

export default {
  components: {
    Cookie,
    Navigation,
    AnchorsMenu,
    Dynamic,
    PageTitle,
    Footer,
  },
  setup() {
    dynamicPage();
    return {
      isXsScreen: useWithinBreakpoint('xs'),
    };
  },
  computed: {
    content() {
      return this.$store.state.router.page?.content;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    meta() {
      return this.$store.state.router.page?.meta;
    },
  },
  mounted() {
    document.querySelector(':root').style.setProperty('--vh', `${this.getViewPortSize()}px`);
    window.addEventListener('resize', () => {
      // We execute the same script as before
      document.querySelector(':root').style.setProperty('--vh', `${this.getViewPortSize()}px`);
    });
  },
  methods: {
    getViewPortSize() {
      return window.innerHeight * 0.01;
    },
  },
};
</script>
