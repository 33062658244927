<template>
  <div class="link--wrapper">
    <ArrowIcon />
    <Go :to="to" target="_blank">
      <slot />
    </Go>
  </div>
</template>

<script>
import Go from '../utils/Go.vue';
import ArrowIcon from '../../assets/images/icon-arrow.svg';

export default {
  components: {
    Go,
    ArrowIcon,
  },
  props: {
    to: String,
  },
};
</script>

<style scoped lang="scss">
.link--wrapper {
    @apply text-center flex items-center gap-1;
    color: inherit;

    svg {
      @apply -rotate-90 h-4.5 -translate-y-0.25 -translate-x-1;
      transition: 0.25s ease-in-out;
      transition-property: transform, color, fill;
      fill: currentColor;
    }

    &:is(:hover, :focus, .hovered) {
      @apply text-red cursor-pointer;

      svg {
        @apply fill-red -translate-x-0;
      }
    }
  }
</style>
