<template>
  <transition name="appear">
    <div v-if="!accepted && footer" id="cookie-wrapper">
      <div v-html="footer.cookietext" />
      <div class="mx-auto mt-4 w-fit">
        <ArrowLink
          class=""
          @click="accepted = true"
        >
          {{ footer.cookieacceptbuttontext }}
        </ArrowLink>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStorage } from '@vueuse/core';
import { useStore } from 'vuex';
import { bootstrap } from 'vue-gtag';
import ArrowLink from '@/components/parts/ArrowLink';

const store = useStore();
const beta = computed(() => store.state.router.beta);
const footer = computed(() => store.state.router.layout.footer);

const accepted = useStorage('acceptCookies', false);

watch(
  [accepted, beta],
  (a) => {
    // not accepted
    if (!a[0]) return;
    // not in prerender
    if (window.prerender) return;
    // not if beta
    if (a[1]) return;
    // start tracking
    bootstrap();
  },
  { immediate: true },
);

</script>

<style scoped lang="scss">
#cookie-wrapper {
  z-index: 1000;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 0;
  @apply fixed p-8 bg-white w-full max-w-none border;

  @media print {
    display:none;
  }

  @screen sm {
    left: inherit;
    right: 2rem;
    bottom: 2rem;
    max-width: 30rem;
    @apply shadow-xl rounded-lg;
  }

  :deep(a) {
    text-decoration: underline;
  }

}
</style>
