module.exports = {
  important: true,
  content: [
    './public/**/*.html',
    './src/**/*.vue',
  ],

  safelist: [
    'bg-gray',
    'bg-gray-700',
    'bg-gray-400',
    'bg-gray-200',
    'bg-gray-100',
    'bg-cyan',
  ],

  theme: {

    colors: {
      black: { DEFAULT: 'var(--color-black)' },
      white: { DEFAULT: 'var(--color-white)' },
      red: {
        DEFAULT: 'var(--color-red)',
      },
      gray: {
        DEFAULT: 'var(--color-gray-medium)',
        100: 'var(--color-gray-white)',
        200: 'var(--color-gray-light)',
        400: 'var(--color-gray-medium)',
        700: 'var(--color-gray-dark)',
        900: 'var(--color-gray-darkest)',
      },
      active: { DEFAULT: 'var(--color-active)' },
      cyan: { DEFAULT: 'var(--color-cyan)' },
      transparent: { DEFAULT: 'transparent' },
    },

    extend: {
      fontFamily: {
        sans: ['Theinhardt', 'Arial', 'sans-serif'],
      },
      zIndex: {
        100: '100',
      },
      gridTemplateColumns: {
        section: 'repeat(2, minmax(50%, 1fr))',
      },
      height: {
        'visible-screen': 'var(--visible-screen)',
        'visible-screen-half': 'calc(var(--visible-screen) / 2)',
      },
      maxWidth: {
        screen: '100vw',
        '1/2': 'calc(50vw - 1.5rem)',
      },
      minHeight: {
        'visible-screen': 'var(--visible-screen)',
        'visible-screen-half': 'max(var(--visible-screen-half), 450px) !important',
        'visible-screen-no-anchors': 'var(--visible-screen-noAnchors)',
        'visible-screen-half-no-anchors': 'var(--visible-screen-half-noAnchors)',
        6: '1.5rem',
        10: '10rem',
        half: '50vh',
      },
      flex: {
        2: '2 2 0%',
      },
    },

    fontSize: {
      inherit: 'inherit',
      xs: '0.75rem',
      sm: '0.9375rem',
      default: '1.25rem',
      base: ['1.375rem', '1.75rem'],
      md: '1.75rem',
      lg: ['2.063rem', '2.625rem'],
      lgxl: ['2.75rem', '3.0625rem'],
      xl: '3rem',
      '2xl': '5rem',
    },

    screens: {
      xxs: '350px',
      xs: '600px',
      sm: '800px',
      md: '1100px',
      lg: '1400px',
      xl: '1900px',
      xxl: '2560px',
    },

    spacing: {
      0: '0px',
      px: '1px',
      0.25: '0.1rem',
      0.5: '0.125rem',
      1: '0.25rem',
      1.5: '0.375rem',
      2: '0.5rem',
      2.5: '0.625rem',
      3: '0.75rem',
      3.5: '0.875rem',
      4: '1rem',
      4.5: '1.125rem',
      5: '1.25rem',
      6: '1.5rem',
      6.5: '1.625rem',
      7: '1.75rem',
      8: '2rem',
      9: '2.25rem',
      10: '2.5rem',
      11: '2.75rem',
      contentgap: '2.75rem',
      12: '3rem',
      14: '3.5rem',
      15: '3.75rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      28: '7rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      44: '11rem',
      48: '12rem',
      50: '12.5rem',
      52: '13rem',
      56: '14rem',
      60: '15rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      86: '21.75rem',
      96: '24rem',
      128: '32rem',
    },

  },

};
