<template>

  <div v-if="payload.iscopyright" class="link copyright">
    <Go><span role="img" aria-label="copyright">© </span>{{ payload.text }}</Go>
  </div>

  <div v-else-if="payload.links" class="multilink">
    <span>{{ payload.text }}</span>
    <div v-for="(link, linkIdx) in payload.links" :key="linkIdx" class="link">
      <Go :to="link.uri" target="_blank">
        <LogoInstagram v-if="link.icon === 'instagram'" />
        <LogoLinkedIn v-if="link.icon === 'linkedin'" />
        <template v-else>
          {{ link.text }}
        </template>
      </Go>
    </div>
  </div>

  <div v-else class="link">
    <Go
      :to="payload.uri"
      :aria-label="payload.text"
    >
      {{ payload.text }}
    </Go>
  </div>

</template>

<script>
import Go from '../utils/Go.vue';
import LogoInstagram from '../../assets/images/logo-instagram.svg';
import LogoLinkedIn from '../../assets/images/logo-linkedin.svg';

export default {
  components: {
    Go,
    LogoInstagram,
    LogoLinkedIn,
  },
  props: {
    payload: Object,
  },
};
</script>

<style lang="scss" scoped>
.link {
  @include mediaxsReverse {
    @include footerItemHover;
    @apply pt-0;

    >a:hover {
      color: inherit !important;
    }
  }

  @include mediamd {
    @apply pt-1;
  }
  @include mediaxs {
    @apply pt-2 pb-4;
  }
}
.multilink {
  @apply flex;
  @include mediamd {
    @apply pt-1 h-full;
  }
  @include mediaxs {
    @apply pt-2 pb-4;
  }

  .link {
    @apply p-0 inline-flex mr-1.5 items-start mt-1;
    height: calc(100% - 0.2rem);

    &:nth-child(2) {
      @apply ml-1.5;
    }
    &:last-child {
      @apply mr-0;
    }
  }

  svg {
    @apply w-auto h-3;
  }
}

.link.copyright {
  @apply pointer-events-none;
  @include mediaxs {
    @apply pb-0 m-0 mb-4;
  }
  @include mediaxxs {
    @apply p-0 m-0 mb-2;
  }
}
</style>
