<template>
  <div id="nav">

    <Go
      class="branding"
      :to="'/' + $store.state.router.locale"
      aria-label="Home"
      @click="hamburgerClose"
    >
      <LogoHome />
    </Go>

    <template v-if="!isSmScreen">
      <nav class="flex gap-7 mx-3 items-center text-base h-full">

        <div
          v-for="(link, linkIdx) in navigation.links"
          :key="linkIdx"
          class="h-full"
        >

          <div v-if="!link.children.length" class="nav-link--wrapper">
            <Go
              :to="link.uri"
              :aria-label="link.title"
              class="nav-link"
            >
              {{ link.title }}
            </Go>
          </div>

          <div
            v-else
            class="nav-link--wrapper"
            @mouseover="showSubmenu(linkIdx)"
            @focusin="showSubmenu(linkIdx)"
            @mouseleave="hideSubmenu(linkIdx)"
            @focusout="hideSubmenu(linkIdx)"
          >
            <Go class="nav-link">
              {{ link.title }}
            </Go>
          </div>

        </div>
      </nav>

      <div class="language">
        <Language />
      </div>

    </template>

    <template v-else>
      <div
        class="hamburger"
        @mouseover="hamburgerHoverOn"
        @focusin="hamburgerHoverOn"
        @mouseleave="hamburgerHoverOff"
        @focusout="hamburgerHoverOff"
        v-on="hamburgerOpened ? {click: hamburgerClose} : {click: hamburgerOpen}"
      >
        <Hamburger :class="{active: hamburgerOpened, hover: hamburgerHovered}" />
      </div>
    </template>

  </div>

  <div
    v-show="!isSmScreen"
    class="dropdown"
    :class="{'opened': dropdownVisibility}"
    @mouseover="keepOpen"
    @focusin="keepOpen"
    @mouseleave="hideSubmenu"
    @focusout="hideSubmenu"
  >
    <Go
      v-for="(subLink, subLinkIdx) in dropdownData"
      :key="subLinkIdx"
      :to="subLink.uri"
      :aria-label="subLink.title"
      class="nav-link"
    >
      {{ subLink.title }}
    </Go>
  </div>

  <div
    class="hamburger-menu"
    :class="{'opened': hamburgerOpened}"
  >
    <div class="hamburger-menu--contents">
      <div class="hamburger-menu--mainlinks">
        <template v-for="link in hamburgerData" :key="link.slug">
          <template v-if="link.isParent">
            <!--          <Go-->
            <!--            v-if="link.isParent"-->
            <!--            class="hamburger-link parent"-->
            <!--          >-->
            <!--            {{ link.title }}-->
            <!--          </Go>-->
          </template>
          <Go
            v-else-if="link.isSublink"
            class="hamburger-link sublink"
            :to="link.uri"
            @click="hamburgerClose"
          >
            {{ link.title }}
          </Go>
          <Go
            v-else
            class="hamburger-link"
            :to="link.uri"
            @click="hamburgerClose"
          >
            {{ link.title }}
          </Go>
        </template>
      </div>
      <div class="hamburger-menu--secondarylinks">
        <Go :to="footer?.contactlink" @click="hamburgerClose">
          {{ footer?.contacttext }}
        </Go>
        <!--        <Go :to="footer?.newsletterlink" target="_blank" @click="hamburgerClose">-->
        <!--          Newsletter-->
        <!--        </Go>-->
        <div class="multilink">
          <span>{{ footer?.followustext }}</span>
          <div class="icons">
            <Go :to="footer?.linkedinlink" @click="hamburgerClose">
              <LogoLinkedIn />
            </Go>
            <Go :to="footer?.instagramlink" @click="hamburgerClose">
              <LogoInstagram />
            </Go>
          </div>
        </div>

        <Language />

      </div>
    </div>
  </div>

</template>

<script>
import Scrolllock from '@/utils/scrolllock';
import { useWithinBreakpoint } from '@/hooks/breakpoints';
import Hamburger from '../utils/Hamburger.vue';
import Language from '../utils/Language.vue';
import LogoLightRed from '../../assets/images/logo-light-red.svg';
import LogoHome from '../../assets/images/logo-home.svg';
import Go from '../utils/Go.vue';
import LogoInstagram from '../../assets/images/logo-instagram.svg';
import LogoLinkedIn from '../../assets/images/logo-linkedin.svg';

export default {
  components: {
    Language,
    Go,
    LogoLightRed,
    LogoHome,
    Hamburger,
    LogoLinkedIn,
    LogoInstagram,
  },
  props: {
    payload: Object,
  },
  setup() {
    return {
      lock: Scrolllock.lock,
      unlock: Scrolllock.unlock,
      isXsScreen: useWithinBreakpoint('xs'),
      isSmScreen: useWithinBreakpoint('sm'),
      isMdScreen: useWithinBreakpoint('md'),
      isLgScreen: useWithinBreakpoint('lg'),
    };
  },
  data() {
    return {
      mobile: false,
      dropdownVisibility: false,
      dropdownData: {},
      hamburgerOpened: false,
      hamburgerHovered: false,
    };
  },
  computed: {
    footer() {
      return this.$store.state.router.layout.footer;
    },
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    hamburgerData() {
      const data = [];
      this.navigation.links.forEach((link) => {
        if (link.children.length) {
          data.push({
            isParent: true, uri: link.uri, slug: link.slug, title: link.title,
          });
          link.children.forEach((sublink) => {
            data.push({
              isSublink: true, uri: sublink.uri, slug: sublink.slug, title: sublink.title,
            });
          });
        } else {
          data.push({
            uri: link.uri, slug: link.slug, title: link.title,
          });
        }
      });
      return data;
    },
  },
  watch: {
    hamburgerOpened() {
      const body = document.querySelector('body');
      if (this.hamburgerOpened) {
        body.style.setProperty('position', 'fixed');
      } else body.style.setProperty('position', 'static');
    },
    isSmScreen() {
      this.dropdownVisibility = false;
      this.dropdownData = [];
    },
  },
  methods: {
    keepOpen() {
      this.dropdownVisibility = true;
    },
    showSubmenu(index) {
      if (this.navigation.links[index].children) {
        this.dropdownData = this.navigation.links[index].children;
        this.dropdownVisibility = true;
      }
    },
    hideSubmenu() {
      this.dropdownVisibility = false;
    },
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
    hamburgerHoverOn() {
      this.hamburgerHovered = true;
    },
    hamburgerHoverOff() {
      this.hamburgerHovered = false;
    },
    hamburgerOpen() {
      this.hamburgerOpened = true;
    },
    hamburgerClose() {
      this.hamburgerOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>

#nav {
  @apply flex justify-center items-center bg-white text-black px-7 relative z-100 w-screen h-20;
  @include mediasm {
    @apply h-16 px-4;
  }
  @include mediamd {
    @apply px-6 pl-[150px];
  }

  .branding {
    @apply flex items-center text-default absolute left-7 top-3 w-50 h-16;
    :deep(svg) {
      @apply w-full;
    }
    @include mediasm {
      @apply h-12 left-4;
      :deep(svg) {
        @apply h-full;
      }
    }
  }
  .language {
    @apply flex items-center h-full gap-6 justify-end absolute right-7 top-0;
    :deep(.Language) {
      @include navItemHover;
      @apply h-full;
      a {
        @apply text-base text-center;
      }
    }
  }

  .hamburger {
    @apply absolute right-2 top-0 h-full w-8 z-100 cursor-pointer;
  }
}

.nav-link--wrapper {
  @apply h-full;

  .nav-link {
    @include navItemHover;
  }
}

.dropdown {
  @apply absolute left-0 w-screen text-center py-1 px-6 flex justify-between items-center gap-6 text-red text-xl bg-white border-b-4 border-red;
  top: calc(var(--main-nav-height));
  height: 0;
  transition: height 0.25s ease-in-out;
  z-index: 99;
  overflow: hidden;

  >a {
    @apply flex-1 text-black;
    @include mediamd {
      @apply text-lgxl;
    }
  }
  &.opened {
    @apply leading-[1.125];
    height: calc(50vh - var(--main-nav-height));

    >a {
      &:is(:hover, :focus) {
        @apply text-red;
      }
      @include mediasm {
        @apply text-black;
      }
    }
  }
}

.hamburger-menu {
  @apply fixed top-0 bottom-0 left-0 right-0 bg-white h-screen;
  overflow-y: hidden;
  z-index: 97;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;

  &.opened {
    transform: translateY(0);
  }

  .hamburger-menu--contents {
    @apply absolute top-9 bottom-0 left-0 z-100 m-7 mb-0 flex flex-col;
    height: calc(100% - 4.5rem);
    width: calc(100% - 3rem);
    a {
      @apply cursor-pointer;
    }
    @include mediasm {
      width: calc(100% - 2rem);
      @apply m-4;
      padding-bottom: 20%;
    }

    .hamburger-menu--mainlinks {
      @apply flex-1 flex flex-col items-center justify-center text-md;

      .hamburger-link {
        @apply text-center;
        &:hover {
          @apply text-red;
        }
        //&:not(.small, .sublink) {
          @apply mt-10;
          @include mediasm {
            @apply mt-4;
          }
        //}

        &.parent {
          @apply mt-10 text-base;
          @include mediasm {
            @apply mt-4;
          }
        }
        &.sublink {

        }
      }
    }
    .hamburger-menu--secondarylinks {
      // flex-basis: 3rem;
      align-self: stretch;
      @apply flex justify-between gap-4 text-center;

      .multilink {
        .icons {
          @apply inline-flex gap-1.5 ml-1.5;
          a {
            svg {
              @apply w-auto h-3;
              :deep(path) {
                fill: var(--color-black) !important;
              }
            }
            &:hover {
              svg :deep(path) {
                fill: var(--color-red) !important;
              }
            }
          }
        }
      }

      :deep(.Language) {
        @apply cursor-pointer;
      }
    }
  }
}

</style>
