<template>

  <div id="nav2" class="">
    <!-- TODO: **in the future**, move inside anchor to avoid using JS -->
    <Logo class="nav-row--logo" :class="{hovered: svgHovered}" />
    <a
      class="nav-row--title"
      @click.prevent="scrollToAndRedirect"
      @mouseover="svgOnEnter"
      @focusin="svgOnEnter"
      @mouseleave="svgOnLeave"
      @focusout="svgOnLeave"
    >
      {{ siteTitle }}
    </a>
    <nav id="subnav" ref="subnav" class="nav-row--links" />
  </div>

</template>

<script>
import Logo from '../../assets/images/logo.svg';

export default {
  components: {
    Logo,
  },
  props: {
    payload: Object,
  },
  data: () => ({
    mobile: false,
    svgHovered: false,
  }),
  computed: {
    navigation() {
      return this.$store.state.router.layout.navigation;
    },
    page() {
      return this.$store.state.router.page;
    },
    siteTitle() {
      return this.page.meta?.projectOwner;
    },
  },
  methods: {
    toggle(close) {
      // eslint-disable-next-line no-param-reassign
      if (close === undefined) close = this.mobile;
      if (close) {
        this.mobile = false;
        setTimeout(() => this.unlock(), 400);
      } else {
        this.mobile = true;
        setTimeout(() => this.lock(), 400);
      }
    },
    scrollToAndRedirect() {
      setTimeout(() => {
        this.$store.dispatch('router/push', { uri: `/${this.$store.state.router.layout.locale}` });
      }, 500);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    svgOnEnter() {
      this.svgHovered = true;
    },
    svgOnLeave() {
      this.svgHovered = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  #nav2 {
    z-index: 97;
    @apply fixed top-0 w-full text-sm flex flex-col;
    @include mediasm {
      display: none;
    }

    .nav-row--logo {
      z-index: 98;
      @apply absolute top-1.5 left-3.5 h-full max-h-5 w-auto;
      @include smoothHover;

      :deep(#logo_svg__Path_1){
        fill: var(--color-white);
        transition: fill 0.3s ease-in-out;
      }
      :deep(#logo_svg__Path_2){
        fill: var(--color-gray-darkest);
        transition: fill 0.3s ease-in-out;
      }
      &.hovered{
        :deep(#logo_svg__Path_2){
          fill: var(--color-red);
        }
      }
    }

    .nav-row--title {
      @apply bg-gray-900 w-full py-1 text-center text-white cursor-pointer;
      @include smoothHover;

      &:hover {
        @apply bg-red text-inherit;
        box-shadow: 0 -1px 0 0 var(--color-black) inset;
      }
    }

    #subnav.nav-row--links {
      @apply flex items-center;
      @include mediamd {
        @apply text-xs;
      }
    }
  }

</style>
